import Keycloak from 'keycloak-js';

const authStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://dev.insights.meld.cx',
    peopleCounting: 'cd0179b4-2647-4adf-bde3-09cc3b4b81a0',
    posCorrelation: '76b5f473-f41c-4d1b-a2bc-1ab556627c70',
    productEngagement: 'f6a17375-a608-4db5-9ca6-f6829313140e',
    shelfEngagement: 'a6061beb-c172-4db1-b039-5a1d3beecc10',
    zoneEngagement: '2af6c747-0ac2-4337-b0ff-0fa237111ee2',
    trafficMeasurement: 'deee8fe6-d039-465a-8d86-ea2c218e08a4',
    aam: 'fd3b06fb-6315-41b7-89b5-0ba34477ead1',
    coffee: '46b7d2d7-ddf0-4d85-ad17-ba2691a304b4',
    samie: '7be37ce7-4dda-4e5a-98d4-401ef23718cc',
    liquorland: '75fd5a7f-065a-4e54-bbbb-aebe12bb808a',
    red: 'd23346f1-b42b-47c6-9447-d566d37b19cd',
    perdue: '5e751947-7b13-4a3a-b9fb-984437611775',
    sampl: 'b3bfc6c2-74e5-4ef1-965d-bc65eec7b543',
    sampp: '38dc75ba-f1b1-4ab5-afb9-23615460f8b4',
    samdt: '0090d2ed-7097-4db5-8a90-893dabce1c3c'
  },
  superset: {
    baseUrl: 'https://superset-staging.internal.viana.ai',
    journeys: '3821d4c7-15fc-45a7-93f0-d846076f7e11',
    red: '6e4f1971-b8ab-46d0-9a95-92d9afedaa89'
  }
};

const endpointsStaging = {
  environment: 'staging',
  base_url: 'https://viana-internal-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-internal-insights-staging-svc.azurewebsites.net/',
  viana_url: 'https://staging.portal.internal.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const endpointsStaging2 = {
  environment: 'staging2',
  // base_url: 'https://viana-services-staging-copy3.azurewebsites.net/',
  // base_url_insights: 'https://viana-insights-staging-auspost.azurewebsites.net/',
  base_url: 'https://viana-services-n16-staging-svc-staging4.azurewebsites.net/',
  base_url_insights: 'https://staging.insights.api.viana.ai/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  viana_url: 'https://staging4.portal.internal.viana.ai//',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsVersaDev = {
  environment: 'versa_dev',
  base_url: 'https://versa-services-dev-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-dev-svc.azurewebsites.net/',
  viana_url: 'https://staging.portal.internal.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVersaStaging = {
  environment: 'versa_staging',
  base_url: 'https://versa-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://versa-insights-staging-svc.azurewebsites.net/',
  viana_url: 'https://staging.portal.internal.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVianaLite = {
  environment: 'viana_lite_internal',
  base_url: 'https://viana-internal-services-staging-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-staging.azurewebsites.net/',
  viana_url: 'https://staging.portal.internal.viana.ai/',
  admin_url: 'https://staging.admin.internal.viana.ai/'
};

const endpointsVianaLitePreproduction = {
  environment: 'viana_lite_internal_preproduction',
  base_url: 'https://viana-internal-services-ppd-svc.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  viana_url: 'https://prep.portal.internal.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/'
};

const authDev = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://dev.insights.meld.cx',
    peopleCounting: 'c0786c24-963d-4e22-a3c3-8b4737aedabb',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    stories: 'e0588ff4-e139-4559-b3a7-e3d980e4c7b9',
    trafficMeasurement: 'e89b0747-4cba-48f6-a009-da3e0634224d',
    aam: '6e1babb3-4e7f-4f70-9754-78f00cd90f7e',
    coffee: 'e696956a-457f-407b-bd78-2e5c73495504',
    samie: '1488a68c-d08f-495a-873d-b2417233a9e8',
    red: '2324ab1c-c079-411e-8d2f-5ea94e3c363f',
    perdue: '5bec409a-0823-4aea-b018-d48f1a0e9823',
    sampl: '',
    sampp: 'a4b67381-b6bf-4b50-9b74-22dba86b504d',
    samdt: '79986eee-c385-4fcd-bec3-d12482865e85'
  },
  superset: {
    baseUrl: 'https://superset-dev.internal.viana.ai',
    journeys: '45eefad2-66e5-4b81-a6bd-afb89d4c3a5f',
    red: '1fa0b4cd-7179-463f-b8fc-05f3133bb8fe'
  }
};

const endpointsDev = {
  environment: 'dev',
  base_url: 'https://viana-dev.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  viana_url: 'https://dev.portal.internal.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  // sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const endpointsNode16Dev = {
  environment: 'node16_dev',
  base_url: 'https://services.node16poc.internal.viana.ai/',
  base_url_insights: 'https://insights.node16poc.internal.viana.ai/',
  viana_url: 'https://dev.portal.internal.viana.ai/',
  admin_url: 'https://admin.node16poc.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsDev2 = {
  environment: 'dev2',
  base_url: 'https://viana-dev-copy2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  viana_url: 'https://dev.portal.internal.viana.ai/',
  admin_url: 'https://dev.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const endpointsPerduePreproduction = {
  environment: 'perdue_ppd',
  base_url: 'https://viana-services-preprod-2.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-perdue.azurewebsites.net/',
  viana_url: 'https://prep.portal.internal.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const authPreproduction = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'preprod',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/preprod/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '864a614e-590f-4abf-8cf2-ec3b7ebecaab',
  scope: 'openid user',
  powerBIReportID: 'cd127ee8-e16b-4f83-8fae-2d096d07ab77',
  powerBIGroupID: '1aa87852-3472-4e22-b260-796f0e301f33',
  yellowfin: {
    baseUrl: 'https://insights.viana.ai',
    peopleCounting: 'cd0179b4-2647-4adf-bde3-09cc3b4b81a0',
    posCorrelation: '644701d4-a8c9-49e0-8ab7-21a3836fe083',
    productEngagement: '0997205e-39fb-4794-b539-efa9f56729d0',
    shelfEngagement: '89a7ad90-f27c-417b-9d24-060d8737dcf5',
    zoneEngagement: '2af6c747-0ac2-4337-b0ff-0fa237111ee2',
    trafficMeasurement: 'deee8fe6-d039-465a-8d86-ea2c218e08a4',
    aam: '4e9488f2-9ac9-478c-b9a7-c63e0b95e45f',
    coffee: 'ac022f06-7a04-4d6c-a8b2-0cff33a1d2d7',
    samie: 'ed474b89-dc39-420e-975b-60aa9698bd91',
    red: 'd23346f1-b42b-47c6-9447-d566d37b19cd',
    perdue: '5bec409a-0823-4aea-b018-d48f1a0e9823',
    sampl: '',
    sampp: '38dc75ba-f1b1-4ab5-afb9-23615460f8b4',
    samdt: ''
  },
  superset: {
    baseUrl: 'https://superset-ppd.internal.viana.ai',
    journeys: '14d06a50-5c3e-4eb4-a113-d5498d196fca',
    red: '239b8710-b61b-42ed-af17-66a2355587c5'
  }
};

const endpointsPreproduction = {
  environment: 'preproduction',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  viana_url: 'https://prep.portal.internal.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const endpointsPreproduction2 = {
  environment: 'preproduction2',
  base_url: 'https://ppd.services.api.viana.ai/',
  base_url_insights: 'https://viana-insights-preprod.azurewebsites.net/',
  viana_url: 'https://prep.portal.internal.viana.ai/',
  admin_url: 'https://prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};


const endpointsNespressoPreproduction = {
  environment: 'nespresso_ppd',
  base_url: 'https://viana-services-preprod-2-nespresso.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-preprod-nespresso.azurewebsites.net/',
  viana_url: 'https://prep.portal.internal.viana.ai/',
  admin_url: 'https://nespresso.prep.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const authProduction = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
  powerBIReportID: 'f105c699-f9b4-49db-8c81-0b31179d8429',
  powerBIGroupID: '75816e02-3bb5-479b-9b13-c5232ba9bbe1',
  yellowfin: {
    baseUrl: 'https://insights.viana.ai',
    peopleCounting: '9530b46a-702a-4254-909d-d415008bc5b0',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '2f70b5e1-72db-43bf-98a5-f0187cf7b15e',
    trafficMeasurement: 'ab4bfbfe-b797-4383-bdf0-8f9cc2ac5125',
    aam: '6e1babb3-4e7f-4f70-9754-78f00cd90f7e',
    coffee: 'e696956a-457f-407b-bd78-2e5c73495504',
    samie: '1488a68c-d08f-495a-873d-b2417233a9e8',
    red: '7c7d0c0e-4d7d-4422-98af-ac8e09d8eec3',
    perdue: '',
    sampl: '',
    sampp: '',
  },
  superset: {
    baseUrl: 'https://cortex.viana.ai',
    samdt: 'dafbd494-29a0-4ea4-a807-252f7648ec3a',
    red: '39f5613c-e59e-45f0-b788-1c872b28cf18',
    peopleCounting: '2a1c351e-7a20-4f8e-ad9b-c7df509f9802',
    trafficMeasurement: 'ff7a1254-eb36-49c7-aa79-d7742edf1c91',
    aam: 'b12dcdad-ef0e-4a6e-8146-d0e924c2ef5d',
    journeys: 'd795bb74-045b-489c-a231-cd95d1a9f3b9'
  }
};

const endpointsProduction = {
  environment: 'production',
  base_url: 'https://services.api.viana.ai/',
  base_url_insights: 'https://insights.api.viana.ai/',
  viana_url: 'https://portal.viana.ai/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const endpointsProduction2 = {
  environment: 'prod2',
  base_url: 'https://viana-services-copy.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-production-copy2.azurewebsites.net/',
  viana_url: 'https://portal.viana.ai/',
  admin_url: 'https://admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/',
  sentry_dsn: 'https://d7989f6efc67488c87afdd0bb065a6bb@sentry.internal.viana.ai/24'
};

const authOptus = {
  auth_url: 'https://id.viana.ai/auth',
  realm: 'production',
  access_token_url: 'https://id.viana.ai/auth/realms/production/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: '63b58278-28c8-43a8-abc6-7698c0146c90',
  scope: 'openid user',
  powerBIReportID: 'fd4a155b-f344-439f-9880-2ec21f6358aa',
  powerBIGroupID: 'a9b83148-febd-4493-a7d3-8d6e04b80180',
  yellowfin: {
    baseUrl: 'https://apac-poc.yellowfin.bi',
    peopleCounting: 'c41091eb-58b0-4628-b736-53e30c03b7c8',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    trafficMeasurement: '88dd3c49-62c5-4bcd-aff2-1c0bcf808500',
    aam: 'b0dc435c-870f-4b9e-b2d3-1ba409435769',
    coffee: '72297534-7197-4390-b4d1-61eba51ddb36',
    samie: '9d458d9d-76c3-417e-97f3-248b99bc6d0b',
  }
};

const endpointsOptus = {
  environment: 'optus',
  base_url: 'https://optus.services.api.viana.ai/',
  base_url_insights: 'https://optus.insights.api.viana.ai/',
  admin_url: 'https://optus.admin.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

const authPCStaging = {
  auth_url: 'https://id.skunkworks.ai/auth',
  realm: 'staging',
  access_token_url: 'https://id.skunkworks.ai/auth/realms/staging/protocol/openid-connect/token',
  client_id: 'viana-portal',
  client_secret: 'c2f34191-5f73-4699-91d2-4283fd97e81c',
  scope: 'openid user',
  powerBIReportID: '4539fb05-ba4a-4f69-b43b-75b6a48691d9',
  powerBIGroupID: ' 13834247-2464-49f5-b013-b35c254e090a',
  yellowfin: {
    baseUrl: 'https://apac-poc.yellowfin.bi',
    peopleCounting: 'c41091eb-58b0-4628-b736-53e30c03b7c8',
    posCorrelation: 'd75f7c86-60c8-45d6-9d1d-ef1412e25fb6',
    productEngagement: '0ca58bc8-8058-4ea7-a3ef-bdc1e9ee207e',
    shelfEngagement: '95c55df6-7830-45f7-9aaf-03dcc21366b7',
    zoneEngagement: '7f60be6e-d6d5-4a64-b7a9-b4a5a31e8ac0',
    trafficMeasurement: '33932758-58d5-4b27-942d-9b910a928874',
    aam: '6e1babb3-4e7f-4f70-9754-78f00cd90f7e',
    coffee: '72297534-7197-4390-b4d1-61eba51ddb36',
    samie: '9d458d9d-76c3-417e-97f3-248b99bc6d0b',
  }
};

const endpointsPCStaging = {
  environment: 'pc-staging',
  base_url: 'https://viana-dev.azurewebsites.net/',
  base_url_insights: 'https://viana-insights-dev.azurewebsites.net/',
  admin_url: 'https://staging.admin.internal.viana.ai/',
  assets_url: 'https://assets.api.viana.ai/'
};

export let auth;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  auth = authProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  auth = authPreproduction;
}else if (process.env.REACT_APP_VIANA_ENV === 'preproduction2') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'perdue_ppd') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  auth = authPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'pc-staging' || process.env.REACT_APP_VIANA_ENV === 'pc-local') {
  auth = authPCStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'optus' || process.env.REACT_APP_VIANA_ENV === 'optus-local') {
  auth = authOptus;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  auth = authDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  auth = authStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  auth = authStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  auth = authPreproduction;
}
else if(process.env.REACT_APP_VIANA_ENV === 'prod2') {
  auth = authProduction;
}
else {
  auth = authStaging;
}

export let endpoints;
if (process.env.REACT_APP_VIANA_ENV === 'production') {
  endpoints = endpointsProduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction') {
  endpoints = endpointsPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'preproduction2') {
  endpoints = endpointsPreproduction2;
} else if (process.env.REACT_APP_VIANA_ENV === 'perdue_ppd') {
  endpoints = endpointsPerduePreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'nespresso_ppd') {
  endpoints = endpointsNespressoPreproduction;
} else if (process.env.REACT_APP_VIANA_ENV === 'pc-staging' || process.env.REACT_APP_VIANA_ENV === 'pc-local') {
  endpoints = endpointsPCStaging;
} else if (process.env.REACT_APP_VIANA_ENV === 'optus' || process.env.REACT_APP_VIANA_ENV === 'optus-local') {
  endpoints = endpointsOptus;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev') {
  endpoints = endpointsDev;
} else if (process.env.REACT_APP_VIANA_ENV === 'dev2') {
  endpoints = endpointsDev2;
} else if (process.env.REACT_APP_VIANA_ENV === 'node16_dev') {
  endpoints = endpointsNode16Dev;
} else if (process.env.REACT_APP_VIANA_ENV === 'staging2') {
  endpoints = endpointsStaging2;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_dev') {
  endpoints = endpointsVersaDev;
}
else if(process.env.REACT_APP_VIANA_ENV === 'versa_staging') {
  endpoints = endpointsVersaStaging;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal') {
  endpoints = endpointsVianaLite;
}
else if(process.env.REACT_APP_VIANA_ENV === 'viana_lite_internal_preproduction') {
  endpoints = endpointsVianaLitePreproduction;
}
else if(process.env.REACT_APP_VIANA_ENV === 'prod2') {
  endpoints = endpointsProduction2;
}
else {
  endpoints = endpointsStaging;
}


const initOptions = {
  url: auth.auth_url, realm: auth.realm, clientId: auth.client_id, onLoad: 'login-required'
};

const keycloak = new Keycloak(initOptions);
export default keycloak;
